@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
  
}



.ant-tooltip-content {
  z-index: 500
}

.ant-slider-tooltip {
  z-index: 400
}

.ant-btn-primary {
  color: #fff;
  background-color: #1677ff;

}

.notification-unread, .notification-read {
  border-radius: 10px; /* Adaugă margini rotunjite cu raza de 10px */
  margin-bottom: 10px; /* Adaugă un spațiu între notificări */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Opțional: adaugă o umbră ușoară pentru un efect de elevație */
}

.notification-unread {
  background-color: #e0f4ff;
}

.ant-switch {
  background-color: #aaa !important; /* Culoare gri pentru fundalul switch-ului când este inactiv */
}

.ant-switch-checked {
  background-color: rgb(41, 126, 206) !important; /* Culoare verde pentru fundalul switch-ului când este activ */
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.proforma-modal .ant-modal-header {
  background-color: #e6f7ff;
}

.proforma-modal .ant-modal-title {
  color: #1890ff;
}

.invoice-modal .ant-modal-header {
  background-color: #f6ffed;
}

.invoice-modal .ant-modal-title {
  color: #52c41a;
}

@tailwind base;
@tailwind components;
@tailwind utilities;